<template>
  <div id="splash">
    <div id="splash-wait">{{ message }}</div>
    <div id="splash-loading"></div>
  </div>
</template>

<script>
import axios from 'axios'
import Config from '@/config'
import events from '@/modules/events'
import auth from '@/modules/auth'

export default {
  name: 'LogoutView',

  props: {
    title: {
      type: String,
      default: 'Saindo com segurança',
    },

    message: {
      type: String,
      default: 'Aguarde enquanto o sistema finaliza sua sessão...',
    },
  },

  data() {
    return {
      http_login: null,
    }
  },

  created() {
    this.http_login = axios.create({
      baseURL: Config.REALM_PB,
    })
  },

  mounted() {
    this.logout()
  },

  methods: {
    async logout() {
      var qs = require('qs')

      var data = qs.stringify({
        token: auth.token,
        client_id: Config.CLIENT_ID,
        refresh_token: auth.refresh_token,
      })

      try {
        // this.$keycloak
        //   .logout({ redirectUri: 'http://localhost:8081' })
        //   .then((success) => {
        //     console.log('LOGOUT COM SUCESSO!')
        //   })
        await this.http_login.post('/protocol/openid-connect/logout', data)
        auth.logout()
        this.$router.push('/')
      } catch (error) {
        events.toastMessage('Erro', 'Falha o encerrar a sessão', 'danger')
        this.$router.push('/')
      }
    },
  },
}
</script>
